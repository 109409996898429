//Header
header {
    background-color: $header-footer-bg-color;
    color: $header-footer-color;
}

// footer
footer {
    max-height: 55px;
    @extend .mt-2;
    .bg {
        background-color: $header-footer-bg-color;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        color: $header-footer-color;
    }
}

.page-header {
    position: relative;
    background-position: 50%;
    background-size: cover;
    margin: 0 -2px;
    padding: 0;
    border: 0;
    height: 100%;
    min-height: 0;
    padding: 25px 0 25px;
    background-color: var(--#{$variable-prefix}primary);
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.75);

    &::before {
        background-color: rgba(0, 0, 0, 0.4);
    }
    &::after,
    &::before {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: block;
        left: 0;
        top: 0;
        content: "";
    }
    .page-info {
        z-index: 2;
        position: relative;
        color: var(--#{$variable-prefix}white);
        text-align: center;
    }
    .page-thumbnail {
        width: 240px;
        height: auto;
        border: 2px solid #ccc;
    }
}

//Sections

section {
    .border-bottom-primary {
        border-bottom: 0.17rem solid $section-title-border-color;
    }

    .section-title {
        @extend .border-bottom-primary;
        @extend .mb-2;
        h1,
        h2,
        h3 {
            @extend .pt-2;
            @extend .mb-0;
            @extend .fs-5;
            @extend .fw-light;
            color: $section-title-color;

            span {
                display: inline-block;
                height: 36px;
                line-height: 36px;
                padding: 0 15px;
                white-space: nowrap;
                background-color: $section-title-bg-color;
                color: $section-title-color;
                border-radius: 2px 2px 0 0;
                margin-bottom: -2px;
            }
        }
    }
}
//filters
.filters {
    @extend .list-unstyled;
    @extend .m-2;

    li {
        @extend .m-1;

        .filter-link {
            @extend .btn;
            @extend .btn-outline-dark;
            @extend .btn-sm;
        }
        .active {
            @extend .btn-dark;
        }
    }
}

//Articles
#article {
    font-size: 1rem;
    .show-more {
        height: 50px;
        overflow: hidden;
        position: relative;
        transition: margin 0.4s;
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100px;
            display: block;
            z-index: 9;
            opacity: 1;
            visibility: visible;
            transition: 0.8s;
        }
    }
    .show-more.visible:after {
        opacity: 0;
        visibility: hidden;
    }
    .show-more-button {
        &:before {
            content: attr(data-more-title);
        }
        i {
            margin-left: 6px;
        }
    }
    .show-more-button.active:before {
        content: attr(data-less-title);
    }
    .show-more-button.active i {
        transform: rotate(180deg);
    }
}

//Video information

#videoInfo {
    h1 {
        margin: 0.2rem 0 0;
        padding: 0;
        font-size: 1.2rem;
        word-break: break-word;
        white-space: normal;
    }
    hr {
        margin: 0.2rem 0;
    }
    .videoPlayer {
        margin-bottom: 0.1rem;
        margin-left: -0.75rem;
        margin-right: -0.75rem;
    }

    .v-info {
        margin-top: 0.6rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .info {
        color: $video-info-color;
        margin: 0 0.25rem;
        p {
            margin: 0;
            padding: 0;
        }
        .posted {
            &:before {
                content: "•";
                margin: 0 4px;
            }
        }
        .share {
            margin-right: 0.5rem;
            font-size: 1.1rem;
            color: $video-info-color;
            @extend .text-uppercase;
        }
    }

    .tags {
        list-style: none;
        margin: 0 0 -0.3rem;
        padding: 0;

        li {
            display: inline;
            margin: 0 1px;
        }

        .tag-link {
            color: $tags-color;
            &:before {
                content: "#";
            }
            &:hover {
                color: $tags-hover-color;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    #videoInfo {
        margin-top: 0.55rem;
        .v-info {
            padding-left: 0;
            padding-right: 0;
        }
        .videoPlayer {
            margin-left: 0;
            margin-right: 0;
        }
    }
}
