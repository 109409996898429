 /* Custom Navbar scroller */
  
 .nav-scroller {
    position: relative;
    z-index: 2;
    height: 2.75rem;
    overflow-y: hidden;
  }
  
  .nav-scroller .nav {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    color: $secondary;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
  
  .nav-underline .nav-link {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 0.875rem;
    color: $secondary;
  }
  
  .nav-underline .nav-link:hover {
    color: $primary;
  }
  
  .nav-underline .active {
    font-weight: 500;
    color: $secondary;
  }
  