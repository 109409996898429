/** Share */
.share .fa {
    padding: 20px;
    font-size: 30px;
    width: 70px;
    text-align: center;
    text-decoration: none;
    margin: 5px 5px;
    border-radius: 0.4rem;
    cursor: pointer;
  }
  .share .fa-facebook {
    background: #1778f2;
    color: white;
  }
  .share .fa-twitter {
    background: #0098f4;
    color: white;
  }
  
  .share .fa-linkedin {
    background: #0067b1;
    color: white;
  }
  .share .fa-pinterest {
    background: #dc0000;
    color: white;
  }
  .share .fa-mail {
    background: #3b3b3b;
    color: white;
  }