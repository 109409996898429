@font-face {
  font-family: "fontello";
  src: url("../font/fontello.eot?47135579");
  src: url("../font/fontello.eot?47135579#iefix") format("embedded-opentype"),
    url("../font/fontello.woff2?47135579") format("woff2"), url("../font/fontello.woff?47135579") format("woff"),
    url("../font/fontello.ttf?47135579") format("truetype"), url("../font/fontello.svg?47135579#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.fa,
.icon {
  display: inline-block;
  font: normal normal normal 14px/1 fontello;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-tint:before {
  content: "\e800";
} /* '' */
.fa-clock-o:before {
  content: "\e801";
} /* '' */
.fa-home:before {
  content: "\e802";
} /* '' */
.fa-bolt:before {
  content: "\e803";
} /* '' */
.fa-eye:before {
  content: "\e804";
} /* '' */
.fa-plus-circle:before {
  content: "\e805";
} /* '' */
.fa-caret-right:before {
  content: "\e806";
} /* '' */
.fa-info-circle:before {
  content: "\e807";
} /* '' */
.fa-calendar:before {
  content: "\e808";
} /* '' */
.fa-random:before {
  content: "\e809";
} /* '' */
.fa-folder-open:before {
  content: "\e80a";
} /* '' */
.fa-users:before {
  content: "\e80b";
} /* '' */
.fa-mail:before {
  content: "\e80c";
} /* '' */
.fa-download:before {
  content: "\e80d";
} /* '' */
.fa-link:before {
  content: "\e80e";
} /* '' */
.fa-search:before {
  content: "\e80f";
} /* '' */
.fa-caret-left:before {
  content: "\e810";
} /* '' */
.fa-star:before {
  content: "\e811";
} /* '' */
.fa-play:before {
  content: "\e812";
} /* '' */
.fa-pause:before {
  content: "\e813";
} /* '' */
.fa-volume-up:before {
  content: "\e814";
} /* '' */
.fa-music:before {
  content: "\e815";
} /* '' */
.fa-award:before {
  content: "\e816";
} /* '' */
.fa-heart:before {
  content: "\e817";
} /* '' */
.fa-upload:before {
  content: "\e818";
} /* '' */
.fa-edit:before {
  content: "\e819";
} /* '' */
.fa-login:before {
  content: "\e81a";
} /* '' */
.fa-logout:before {
  content: "\e81b";
} /* '' */
.fa-flag:before {
  content: "\e81c";
} /* '' */
.fa-star-empty:before {
  content: "\e81d";
} /* '' */
.fa-videocam:before {
  content: "\e81e";
} /* '' */
.fa-thumbs-up:before {
  content: "\e81f";
} /* '' */
.fa-list-add:before {
  content: "\e820";
} /* '' */
.fa-thumbs-down:before {
  content: "\e821";
} /* '' */
.fa-dot-3:before {
  content: "\e822";
} /* '' */
.fa-twitter:before {
  content: "\f099";
} /* '' */
.fa-facebook:before {
  content: "\f09a";
} /* '' */
.fa-filter:before {
  content: "\f0b0";
} /* '°' */
.fa-list:before {
  content: "\f0ca";
} /* 'Ê' */
.fa-pinterest:before {
  content: "\f0d2";
} /* 'Ò' */
.fa-linkedin:before {
  content: "\f0e1";
} /* 'á' */
.fa-cloud-download:before {
  content: "\f0ed";
} /* 'í' */
.fa-bell:before {
  content: "\f0f3";
} /* 'ó' */
.fa-angle-up:before {
  content: "\f106";
} /* '' */
.fa-angle-down:before {
  content: "\f107";
} /* '' */
.fa-play-circle:before {
  content: "\f144";
} /* '' */
.fa-youtube-play:before {
  content: "\f16a";
} /* '' */
.fa-arrow-circle-o-right:before {
  content: "\f18e";
} /* '' */
.fa-arrow-circle-o-left:before {
  content: "\f190";
} /* '' */
.fa-google:before {
  content: "\f1a0";
} /* '' */
.fa-file-video-o:before {
  content: "\f1c8";
} /* '' */
.fa-share:before {
  content: "\f1e0";
} /* '' */
.fa-bell-o:before {
  content: "\f1f6";
} /* '' */
.fa-trash:before {
  content: "\f1f8";
} /* '' */
.fa-whatsapp:before {
  content: "\f232";
} /* '' */
.fa-user-o:before {
  content: "\f2c0";
} /* '' */
.fa-telegram:before {
  content: "\f2c6";
} /* '' */
.fa-cancel:before {
  content: "\e824";
} /* '' */
.fa-ok-circled:before {
  content: "\e825";
} /* '' */
