$plyr-color-main: #6610f2;
@import "~plyr/src/sass/plyr";

#inline-player {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
  }