// Body

$variable-prefix: p-;
$primary: #6610f2;
// Typography
$font-family-sans-serif: "Nunito", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
$link-decoration: none;
// $container-padding-x: 0.3rem !default;

//Header color
$header-footer-bg-color: #5f3bc5;
$header-footer-color: #fff;


//Navs

$navbar-dark-color: #fff;
// $breadcrumb-padding-x: 0.5rem;
// $breadcrumb-padding-y: 0.5rem;
// $breadcrumb-margin-bottom: 0;
// $breadcrumb-bg: var(--#{$variable-prefix}body-bg);
// $breadcrumb-border-radius: 0.25rem;

//Section Titles

$section-title-border-color: #6610f2;
$section-title-bg-color: #6610f2;
$section-title-color: #fff;

//Bottom Nav mobile
$bottom-nav-height: 55px;
$bottom-nav-background: #fff;
$bottom-nav-color: #212529;
$bottom-nav-link-hover-color: #eeeeee;


//Grid Videos
$card-video-color:#6610f2;
$card-video-body-span-color: #212529;
$card-video-badge-bg-color:rgba(0, 0, 0, 0.726);
$card-category-playlist-bg-color:rgba(0, 0, 0, 0.548);
$card-category-playlist-color:#fff;

//Videos List
$video-list-color: #212529;
$video-list-bg-color: #fff;


$video-info-color: #6c757d;




//Video Tags

$tags-color: #055fd5;
$tags-hover-color: #6610f2;

$form-file-button-bg:  $primary;