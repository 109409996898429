/* Movie Card Items */
.video,
.playlist,
.category {
  @extend .shadow-sm;
  color: $card-video-color;
  img {
    width: 100%;
  }
  .thumb-wrapper {
    overflow: hidden;
    @extend .position-relative;
  }

  .vthumb-wrapper {
    overflow: hidden;
    @extend .position-relative;
  }

  .thumb {
    @extend .ratio;
    @extend .ratio-16x9;
  }
  .duration {
    @extend .position-absolute;
    @extend .bottom-0;
    @extend .end-0;
    @extend .m-1;
    @extend .badge;
    font-weight: 400;
    background-color: $card-video-badge-bg-color;
  }

  .right-top {
    @extend .position-absolute;
    @extend .top-0;
    @extend .end-0;
    @extend .m-1;
    @extend .badge;
    font-weight: 400;
    background-color: $card-video-badge-bg-color;
  }

  .playlist-body,
  .category-body {
    @extend .position-absolute;
    @extend .bottom-0;
    @extend .start-50;
    @extend .translate-middle-x;
    @extend .rounded-bottom;
    font-weight: 400;
    width: 100%;
    color: $card-category-playlist-color;
    background-color: $card-category-playlist-bg-color;
  }
  .playlist-body {
    h4,
    .title {
      @extend .text-truncate;
      @extend .p-1;
      margin: 2px;
      font-size: 1.15rem;
      font-weight: 500;
      line-height: 1;
    }
  }

  .category-body {
    h4,
    .title {
      @extend .text-truncate;
      @extend .p-1;
      margin: 2px;
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1;
      text-align: center;
    }
  }

  .video-body {
    flex: 1 1 auto;
    padding: 6px;
    h4,
    .title {
      @extend .text-truncate;
      @extend .p-1;
      margin: 0;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1;
    }
    span {
      font-size: 0.8rem;
      font-weight: $font-weight-lighter;
      color: $card-video-body-span-color;
      margin: 1px;
      padding: 1px;
    }
  }
}

.video-list {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  @extend .mb-3;

  li {
    position: relative;
    display: block;
    padding: 0.2rem 0;
    color: $video-list-color;
    background-color: $video-list-bg-color;
  }

  img {
    width: 160px;
    height: auto;
  }
  .thumb-wrapper {
    overflow: hidden;
    @extend .position-relative;
  }
  .duration {
    @extend .position-absolute;
    @extend .bottom-0;
    @extend .end-0;
    @extend .m-1;
    @extend .badge;
    font-weight: 400;
    background-color: $card-video-badge-bg-color;
  }

  h4,
  .title {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-weight: 500;
    word-wrap: break-word;
    line-height: 1.2;
    max-height: 2.25rem;
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
  }
}

.thumb-wrapper:hover .thumb {
  transform: scale(1.3);
  transition: transform 0.3s;
}

.play-hover, .thumb-hover {
  position: absolute !important;
  opacity: 0;
  border-radius: 10px;
  transition: all 0.3s ease;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}
.video .v-icon {
  width: 2.7rem;
  height: 2.7rem;
  color: #fff;
  background-color: $primary;
  font-size: 1rem;
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  cursor: pointer;
}

.vthumb-wrapper:hover .play-hover {
  opacity: 1;
}

.thumb-wrapper:hover .thumb-hover {
  opacity: 1;
}