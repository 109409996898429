#secondary-navbar {
  margin-top: 50px;
  .bottom-nav {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: $bottom-nav-height;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    background-color: $bottom-nav-background;
    display: flex;
    overflow-x: auto;
    color: $bottom-nav-color;

    .nav-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      min-width: 50px;
      overflow: hidden;
      white-space: nowrap;
      font-size: 13px;
      color: $bottom-nav-color;
      text-decoration: none;
      -webkit-tap-highlight-color: transparent;
      transition: background-color 0.1s ease-in-out;
    }
    .nav-link:hover {
      background-color: $bottom-nav-link-hover-color;
    }

    .active {
      color: $primary;
    }
    i {
      font-size: 18px;
    }
  }
}

.secondary-nav {
  width: 100%;
  height: $bottom-nav-height;
  display: flex;
  overflow-x: auto;
  color: $bottom-nav-color;

  .nav-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    min-width: 50px;
    overflow: hidden;
    white-space: nowrap;
    font-size: 13px;
    color: $bottom-nav-color;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    transition: background-color 0.1s ease-in-out;

  }

  .active {
    color: $primary;
  }

  i {
    font-size: 1.5rem;
  }
}
